import { Divider, Typography } from '@mui/material'
import React from 'react'

import Layout from '../components/Layout'
import GuideHeader from '../components/navigation/GuideHeader'

const browser = typeof window !== 'undefined' && window
const NotFoundPage = ({ location }) =>
  browser && (
    <Layout location={location}>
      <GuideHeader title="Page Not Found" subTitle="404" />
      <Divider />
      <Typography>Sorry, this page was not found.</Typography>
    </Layout>
  )

export default NotFoundPage
